import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import HowToFindUs from '../components/Page/HowToFindUs'
import BannerImage from '../components/Page/BannerImage'

const locationPage = ({data}) => {
	return (
        <Layout>
			<BannerImage image={data.file.childImageSharp.gatsbyImageData} />
			<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
				<div className="md:w-1/2">
					<HowToFindUs />
				</div>
				<div className="md:w-1/2">

				</div>
			</section>
		</Layout>
    );
}

export const query = graphql`{
  file(relativePath: {eq: "banner/Boswell-Book-Festival-How-to-Find-Us.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default locationPage
