import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const HowToFindUs = () => {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: {eq:"how-to-find-us.md"}) {
				childMarkdownRemark {
					html
					frontmatter {
						id
						title
					}
				}
			}
		}
	`)

	return (
		<section id="/how-to-find-us" className="p-4 md:p-12">
			<div className="markdown-content" dangerouslySetInnerHTML={{ __html: data.file.childMarkdownRemark.html }}></div>
		</section>
	)
}

export default HowToFindUs
